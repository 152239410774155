import React from 'react';
import SEO from 'react-seo-component';

import { Layout } from '../components/Layout';
import { useSiteMetadata } from '../hooks/useSiteMetadata';

export default () => {
  const {
    description,
    title,
    siteUrl,
    siteLanguage,
    siteLocale
  } = useSiteMetadata();
  return (
    <Layout>
      <SEO
        title={title}
        titleTemplate={title}
        description={description}
        pathname={siteUrl}
        siteLanguage={siteLanguage}
        siteLocale={siteLocale}
      />
    </Layout>
  );
};
